import Wrapper from "@/components/wrapper";
import { FC, useEffect, useState } from "react";
import TabButton from "./components/TabButton";
import { Button, Modal, Show } from "@/components";
import DetailForm from "./forms/DetailForm";
import { useFormik } from "formik";
import DescriptionForm from "./forms/DescriptionForm";
import PreviewForm from "./forms/PreviewForm";
import { useAppDispatch } from "@/redux/hook";
import { clearCreateJob, setCreateJobDescription, setCreateJobDetails } from "@/redux/job/createJobSlice";
import * as Yup from "yup";
import PricingItem from "./components/PricingItem";
import { useDraftJobs, usePackages } from "@/hooks";
import { useMutation } from "@apollo/client";
import { DELETE_DRAFT_JOB, DRAFT_JOB, POST_JOB_MUTATION, UPDATE_DRAFTED_JOB } from "@/apollo/graphql/mutations/job";
import { CreateJobMutation, CreateJobMutationVariables, DeleteDraftedJobMutation, DeleteDraftedJobMutationVariables, DraftJob, DraftJobMutation, DraftJobMutationVariables, Sort, UpdateDraftMutation, UpdateDraftMutationVariables } from "@/apollo/generated/types";
import { useAppNavigate, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import _ from "lodash";
import toast from "react-hot-toast";
import { useUrlState } from "@/utils";
import moment from "moment";
import DefaultLoader from "@/components/loaders/default-loader";
import { CurrencyOption, SalaryDurationOption } from "@/data";
import EnterprisePage from "@/components/enterprise-page";
import logo from "@/assets/images/company-logo-sec.png";
import { useActiveJobLength } from "@/hooks/useActiveJobLength";

type Props = {};

const colors = ["bg-blue-500", "bg-green-500", "bg-violet-500", "bg-yellow-500", "bg-indigo-500"];

const PostJobPage: FC<Props> = () => {
    const navigate = useAppNavigate();
    const employer = useCurrentUser();
    const [draftId, setDraftId] = useUrlState("draft_id");
    const [modal, setModal] = useUrlState("modal");
    const [contactSales, setContactSales] = useState<"Pricing" | "Contact">("Pricing");
    const [selectedJob, setSelectedJob] = useState<DraftJob>();
    const [urgent, setUrgent] = useState(false);
    const [jobName, setJobName] = useState("");
    const { activeJobs } = useActiveJobLength();

    const [postJob, { loading: postLoading }] = useMutation<CreateJobMutation, CreateJobMutationVariables>(POST_JOB_MUTATION, { refetchQueries: ["GetJobs", "GetJobStats", "GetActiveJobsLength"] });
    const [draftJob, { loading: draftLoading }] = useMutation<DraftJobMutation, DraftJobMutationVariables>(DRAFT_JOB, { refetchQueries: ["GetDraftedJobs", "GetJobStats"] });
    const [updateDraftJob, { loading: draftUpdatingLoading }] = useMutation<UpdateDraftMutation, UpdateDraftMutationVariables>(UPDATE_DRAFTED_JOB, { refetchQueries: ["GetDraftedJobs", "GetJobStats"] });
    const [deleteDraftJob] = useMutation<DeleteDraftedJobMutation, DeleteDraftedJobMutationVariables>(DELETE_DRAFT_JOB, { refetchQueries: ["GetJobs", "GetJobStats"] });

    const { packages, loading: packageLoading } = usePackages({ variables: { filter: { specialPackage: false }, sort: { price: Sort.Desc } } });

    const {
        drafted_jobs,
        loading: loadingDrafted,
        refetch: refetchDrafted,
    } = useDraftJobs({
        variables: {
            filter: { id: draftId ? { eq: draftId } : undefined },
            pagination: { limit: 1, offset: 0 },
            sort: {},
        },
    });

    const [activeTab, setActiveTab] = useUrlState("tab");
    const dispatch = useAppDispatch();

    const postJobDetailForm = useFormik({
        initialValues: {
            jobTitle: "",
            jobType: "",
            modeOfWork: "",
            jobLocation: "",
            openingDate: "",
            closingDate: "",
            duration: SalaryDurationOption[0].value || "",
            currency: CurrencyOption[0].value || "",
            salaryRange: false,
            minimumSalary: 0,
            maximumSalary: 0,
            urgent: false,
            vacancies: 0,
        },
        validationSchema: Yup.object().shape({
            jobTitle: Yup.string().required("Job title is required"),
            jobType: Yup.string().required("Job type is required"),
            jobLocation: Yup.string().required("Job location is required"),
            modeOfWork: Yup.string().required("Mode of work is required"),
            vacancies: Yup.number().min(1, "Openings must be greater than or equal to 1").required("Openings is required"),
            openingDate: Yup.date().required("Opening date is required"),
            closingDate: Yup.date().required("Closing date is required").min(Yup.ref("openingDate"), "Closing date cannot be before the opening date"),
            minimumSalary: Yup.number()
                .nullable()
                .test("min-price-validation", "Min salary is incorrecct", function (value) {
                    const { maximumSalary } = this.parent;
                    if (maximumSalary !== undefined && maximumSalary !== null) {
                        if (value === undefined || value === null) {
                            return false; // Min price is required when max price is set
                        }
                        return value <= maximumSalary; // Min price cannot be greater than max price
                    }
                    return true;
                }),
            maximumSalary: Yup.number()
                .nullable()
                .test("max-price-validation", "Max salary is incorrect", function (value) {
                    const { minimumSalary } = this.parent;
                    if (minimumSalary !== undefined && minimumSalary !== null) {
                        if (value === undefined || value === null) {
                            return false; // Max price is required when min price is set
                        }
                        return value >= minimumSalary; // Max price cannot be less than min price
                    }
                    return true;
                }),
        }),
        onSubmit: (values) => {
            dispatch(
                setCreateJobDetails({
                    ...values,
                })
            );
            setActiveTab("Description");
        },
    });

    const postJobDescriptionForm = useFormik({
        initialValues: {
            about: "",
            responsibilities: "",
            requirements: "",
            benefits: "",
        },
        validationSchema: Yup.object().shape({
            about: Yup.string().required("About is required"),
            responsibilities: Yup.string().required("Responsibilities is required"),
            requirements: Yup.string().required("Requirements is required"),
            benefits: Yup.string().optional(),
        }),
        onSubmit: (values) => {
            dispatch(
                setCreateJobDescription({
                    ...values,
                })
            );
            setActiveTab("Preview");
        },
    });

    const handleDraft = async () => {
        if (draftId) {
            await updateDraftJob({
                variables: {
                    updateDraftId: draftId,
                    input: {
                        companyId: employer?.companyId || "",
                        about: postJobDescriptionForm.values.about,
                        requirements: postJobDescriptionForm.values.requirements,
                        responsibilities: postJobDescriptionForm.values.responsibilities,
                        benefits: postJobDescriptionForm.values.benefits,
                        title: postJobDetailForm.values.jobTitle,
                        type: postJobDetailForm.values.jobType,
                        location: postJobDetailForm.values.jobLocation,
                        mode: postJobDetailForm.values.modeOfWork,
                        openDate: moment(postJobDetailForm.values.openingDate).toDate(),
                        closeDate: moment(postJobDetailForm.values.closingDate).toDate(),
                        urgent: postJobDetailForm.values.urgent,
                        vacancies: postJobDetailForm.values.vacancies,
                        salary: {
                            currency: postJobDetailForm.values.currency,
                            maximum: postJobDetailForm.values.maximumSalary,
                            minimum: postJobDetailForm.values.minimumSalary,
                            frequency: postJobDetailForm.values.duration,
                        },
                    },
                },
            })
                .then(() => {
                    postJobDescriptionForm.resetForm();
                    postJobDetailForm.resetForm();
                    dispatch(clearCreateJob());
                    setActiveTab(undefined);
                    toast(JSON.stringify({ type: "success", title: "Job Draft Updated Successfully" }));
                    navigate({ to: "/" });
                })
                .catch(() => {
                    toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                });
        } else {
            await draftJob({
                variables: {
                    input: {
                        companyId: employer?.companyId || "",
                        about: postJobDescriptionForm.values.about,
                        requirements: postJobDescriptionForm.values.requirements,
                        responsibilities: postJobDescriptionForm.values.responsibilities,
                        benefits: postJobDescriptionForm.values.benefits,
                        title: postJobDetailForm.values.jobTitle,
                        type: postJobDetailForm.values.jobType,
                        location: postJobDetailForm.values.jobLocation,
                        mode: postJobDetailForm.values.modeOfWork,
                        openDate: moment(postJobDetailForm.values.openingDate).toDate(),
                        closeDate: moment(postJobDetailForm.values.closingDate).toDate(),
                        urgent: postJobDetailForm.values.urgent,
                        vacancies: postJobDetailForm.values.vacancies,
                        salary: {
                            currency: postJobDetailForm.values.currency,
                            maximum: postJobDetailForm.values.maximumSalary,
                            minimum: postJobDetailForm.values.minimumSalary,
                            frequency: postJobDetailForm.values.duration,
                        },
                    },
                },
            })
                .then(() => {
                    postJobDescriptionForm.resetForm();
                    postJobDetailForm.resetForm();
                    dispatch(clearCreateJob());
                    setActiveTab(undefined);
                    toast(JSON.stringify({ type: "success", title: "Job Drafted Successfully" }));
                    navigate({ to: "/" });
                })
                .catch(() => {
                    toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                });
        }
    };

    const handleSubmit = async () => {
        if (Number(employer?.company?.subscription?.package?.jobsQuota || 0) > Number(activeJobs || 0)) {
            await postJob({
                variables: {
                    input: {
                        companyId: employer?.companyId || "",
                        about: postJobDescriptionForm.values.about,
                        requirements: postJobDescriptionForm.values.requirements,
                        responsibilities: postJobDescriptionForm.values.responsibilities,
                        benefits: postJobDescriptionForm.values.benefits,
                        title: postJobDetailForm.values.jobTitle,
                        type: postJobDetailForm.values.jobType,
                        location: postJobDetailForm.values.jobLocation,
                        mode: postJobDetailForm.values.modeOfWork,
                        openDate: moment(postJobDetailForm.values.openingDate).toDate(),
                        closeDate: moment(postJobDetailForm.values.closingDate).toDate(),
                        urgent: postJobDetailForm.values.urgent ? 1 : 0,
                        vacancies: postJobDetailForm.values.vacancies,
                        salary: {
                            currency: postJobDetailForm.values.currency,
                            maximum: postJobDetailForm.values.maximumSalary.toString(),
                            minimum: postJobDetailForm.values.minimumSalary.toString(),
                            frequency: postJobDetailForm.values.duration,
                        },
                    },
                },
            })
                .then(() => {
                    setUrgent(postJobDetailForm.values.urgent);
                    setJobName(postJobDetailForm.values.jobTitle);
                    if (draftId) {
                        postJobDescriptionForm.resetForm();
                        postJobDetailForm.resetForm();
                        dispatch(clearCreateJob());
                        setActiveTab(undefined);
                        return deleteDraftJob({
                            variables: {
                                companyId: employer?.companyId || "",
                                jobId: draftId || "",
                            },
                        });
                    } else {
                        postJobDescriptionForm.resetForm();
                        postJobDetailForm.resetForm();
                        dispatch(clearCreateJob());
                        setModal("post_success");
                        setActiveTab(undefined);
                        toast(JSON.stringify({ type: "success", title: "Job Created Successfully" }));
                    }
                })
                .then(() => {
                    if (draftId) {
                        toast(JSON.stringify({ type: "success", title: "Job Created From Drafted Job Successfully" }));
                        setDraftId(undefined);
                        setSelectedJob(undefined);
                        setModal("post_success");
                    }
                })
                .catch((err) => {
                    toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                });
        } else {
            toast(
                JSON.stringify({
                    type: "error",
                    title: "Active job quota exceeded",
                })
            );
        }
    };

    // refetch Drafted job
    useEffect(() => {
        if (draftId) {
            refetchDrafted();
        }
    }, [draftId]);

    // set selected drafted job
    useEffect(() => {
        if (!_.isEmpty(drafted_jobs) && draftId) {
            setSelectedJob(drafted_jobs?.at(0) as DraftJob);
        }
    }, [drafted_jobs, loadingDrafted, draftId]);

    // set drafted jobs
    useEffect(() => {
        if (!_.isEmpty(selectedJob) && draftId) {
            postJobDescriptionForm.setValues({
                about: selectedJob?.about || "",
                responsibilities: selectedJob?.responsibilities || "",
                requirements: selectedJob?.requirements || "",
                benefits: selectedJob?.benefits || "",
            });
            dispatch(
                setCreateJobDetails({
                    jobTitle: selectedJob?.title || "",
                    jobType: selectedJob?.type || "",
                    modeOfWork: selectedJob?.mode || "",
                    jobLocation: selectedJob?.location || "",
                    openingDate: moment(selectedJob?.openDate).format("YYYY-MM-DD"),
                    closingDate: moment(selectedJob?.closeDate).format("YYYY-MM-DD"),
                    duration: selectedJob?.salary?.frequency || SalaryDurationOption[0].value,
                    currency: selectedJob?.salary?.currency || CurrencyOption[0].value,
                    minimumSalary: selectedJob?.salary?.minimum || 0,
                    maximumSalary: selectedJob?.salary?.maximum || 0,
                    vacancies: selectedJob?.vacancies || 0,
                    urgent: selectedJob?.urgent || false,
                })
            );
            dispatch(
                setCreateJobDescription({
                    about: selectedJob?.about || "",
                    responsibilities: selectedJob?.responsibilities || "",
                    requirements: selectedJob?.requirements || "",
                    benefits: selectedJob?.benefits || "",
                })
            );

            postJobDetailForm.setValues({
                jobTitle: selectedJob?.title || "",
                jobType: selectedJob?.type || "",
                modeOfWork: selectedJob?.mode || "",
                jobLocation: selectedJob?.location || "",
                openingDate: moment(selectedJob?.openDate).format("YYYY-MM-DD"),
                closingDate: moment(selectedJob?.closeDate).format("YYYY-MM-DD"),
                duration: selectedJob?.salary?.frequency || SalaryDurationOption[0].value,
                currency: selectedJob?.salary?.currency || CurrencyOption[0].value,
                salaryRange: (selectedJob?.salary?.maximum || 0) > 0,
                minimumSalary: selectedJob?.salary?.minimum || 0,
                maximumSalary: selectedJob?.salary?.maximum || 0,
                vacancies: selectedJob?.vacancies || 0,
                urgent: selectedJob?.urgent || false,
            });
        }
    }, [selectedJob]);

    const handleClear = () => {
        dispatch(clearCreateJob());
        postJobDescriptionForm.resetForm();
        postJobDetailForm.resetForm();
        setDraftId(undefined);
        setSelectedJob(undefined);
    };

    return (
        <>
            {" "}
            <Wrapper>
                <div className="relative w-full p-5 h-full ">
                    {/* !_.isEmpty(employer?.company?.subscription?.status) */}
                    <Show if={!_.isEmpty(employer?.company?.subscription?.status) && modal !== "package"}>
                        <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                            <div className="flex justify-start items-center border-b w-full h-10 text-sm lg:space-x-10 space-x-5 overflow-scroll overflow-x-scroll no-scrollbar sticky top-0 z-30 bg-white">
                                <TabButton title={"Details"} active={_.isEmpty(activeTab)} setActive={() => setActiveTab(undefined)} />
                                <TabButton title={"Description"} active={activeTab === "Description"} setActive={() => setActiveTab("Description")} />
                                <TabButton title={"Preview"} active={activeTab === "Preview"} setActive={() => setActiveTab("Preview")} />
                            </div>
                            {draftId && loadingDrafted ? (
                                <div className="w-full h-full">
                                    <DefaultLoader />
                                </div>
                            ) : (
                                <div className="w-full mt-5">
                                    <Show if={_.isEmpty(activeTab)}>
                                        <DetailForm form={postJobDetailForm} handleClear={handleClear} />
                                    </Show>
                                    <Show if={activeTab === "Description"}>
                                        <DescriptionForm form={postJobDescriptionForm} setActive={() => setActiveTab(undefined)} />
                                    </Show>
                                    <Show if={activeTab === "Preview"}>
                                        <PreviewForm
                                            setActive={() => setActiveTab("Description")}
                                            handleSubmit={handleSubmit}
                                            loading={postLoading}
                                            loadingDraft={draftLoading}
                                            loadingUpdated={draftUpdatingLoading}
                                            draftId={draftId}
                                            handleDraft={handleDraft}
                                            employerQuota={Number(employer?.company?.subscription?.package?.jobsQuota || 0)}
                                            activeQuota={Number(activeJobs || 0)}
                                        />
                                    </Show>
                                </div>
                            )}
                        </div>
                    </Show>
                    {/* _.isEmpty(employer?.company?.subscription?.status) */}
                    <Show if={_.isEmpty(employer?.company?.subscription?.status) || modal === "package"}>
                        <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                            <div className="flex justify-start items-center border-b w-full h-10 text-sm lg:space-x-10 space-x-5 overflow-scroll overflow-x-scroll no-scrollbar sticky top-0 z-30 bg-white">
                                <TabButton title={"Pricing"} active={true} setActive={() => {}} />
                            </div>
                            {contactSales === "Contact" ? (
                                <EnterprisePage
                                    setTab={() => {
                                        setContactSales("Pricing");
                                    }}
                                />
                            ) : (
                                <div className="w-full mt-5">
                                    <div className="mb-5">
                                        <h1 className="text-lg font-semibold leading-6 ">Simple and transparent pricing</h1>
                                        <h5 className="text-xs mt-2">Choose the right package.</h5>
                                    </div>
                                    <div className="hidden md:flex my-5 w-full flex-col justify-center items-center">
                                        <h3 className="font-semibold mb-2 text-sm">Call for Custom Quote</h3>
                                        <div className="w-96 border border-black/5 rounded-lg flex justify-between items-center h-16 ">
                                            <div className="w-7/12 flex flex-col justify-center items-center h-full  rounded-lg bg-emerald-100">
                                                <h3 className="text-xs font-semibold leading-4 ">ENTERPRISE</h3>
                                                <p className="text-[10px] text-center font-light">Elite solution for scalable hiring needs.</p>
                                            </div>
                                            <div className="w-5/12 flex flex-col justify-center items-center h-full ">
                                                <button className="text-[10px] text-white px-3 py-1 bg-primary-300 rounded" onClick={() => setContactSales("Contact")}>
                                                    Contact Sales
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {packageLoading ? (
                                        <div className="w-full h-full">
                                            <DefaultLoader />
                                        </div>
                                    ) : (
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 pt-5">
                                            {packages?.map((data, index) => (
                                                <PricingItem
                                                    id={data?.id}
                                                    key={data?.id}
                                                    color={colors[index % 4]}
                                                    planCode={data?.planCode || ""}
                                                    title={data?.name}
                                                    description={data?.description}
                                                    interval={data?.interval || ""}
                                                    price={data?.price}
                                                    features={[`Upto ${data?.jobsQuota} jobs postings`].concat(data?.privileges)}
                                                    disabled={!_.isEmpty(employer?.company?.subscription?.package)}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Show>
                </div>
            </Wrapper>
            {modal === "post_success" && (
                <Modal
                    title={""}
                    hideActions={true}
                    size="md"
                    open={modal === "post_success"}
                    setOpen={() => {
                        navigate({
                            to: "/",
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        });
                        setJobName("");
                        setUrgent(false);
                    }}>
                    <div className="w-full h-full  flex flex-col justify-center items-center space-y-3 py-10">
                        <img src={logo} className="h-14 w-14 rounded-full bg-cover object-cover" />
                        <h1 className="text-lg font-medium">Congratulations &#127881;</h1>
                        {urgent ? (
                            <p>
                                You have successfully posted <span className="font-semibold">{_.startCase(jobName)}</span> as urgent. Please be confident that we will assist you promptly finding the right talent
                            </p>
                        ) : (
                            <p className="text-sm text-light text-center">We applaud you for listing your job with us. Now let's help you recruit the right talent!</p>
                        )}
                        <div className="pt-5">
                            <Button
                                size="sm"
                                onClick={() => {
                                    navigate({
                                        to: "/",
                                        search(prev) {
                                            return { ...prev, modal: undefined };
                                        },
                                    });
                                    setJobName("");
                                    setUrgent(false);
                                }}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PostJobPage;
