import { Drawer, Show } from "@/components";
import { ClockLoader } from "react-spinners";
import { useEffect, useState } from "react";
import RepostTabButton from "./RepostTabButton";
import RepostJobDetail from "./forms/RepostJobDetail";
import RepostJobPreview from "./forms/RepostJobPreview";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useUrlState } from "@/utils";
import { useJob } from "@/hooks";
import { useAppDispatch } from "@/redux/hook";
import { clearRepostJob, setRepostDetails, setRepostJobPreview } from "@/redux/job/repostJobSlice";
import moment from "moment";
import { RepostJobMutation, RepostJobMutationVariables } from "@/apollo/generated/types";
import { REPOST_JOB } from "@/apollo/graphql/mutations/job";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import { useActiveJobLength } from "@/hooks/useActiveJobLength";

type Props = { open: boolean; setOpen: (value: boolean) => void };

const RepostDrawer = ({ open, setOpen }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const employer = useCurrentUser();
    const { activeJobs } = useActiveJobLength();


    const [active, setActive] = useState<string>("Date Adjustment");
    const [jobId] = useUrlState("id");

    const [repostJob, { loading: loadingRepost }] = useMutation<RepostJobMutation, RepostJobMutationVariables>(REPOST_JOB, { refetchQueries: ["GetJobs", "GetJobStats", "GetActiveJobsLength"] });
    const { job, loading } = useJob({
        variables: {
            jobId: jobId || "",
        },
    });

    const repostJobDetailForm = useFormik({
        initialValues: {
            openingDate: "",
            closingDate: "",
        },
        validationSchema: Yup.object().shape({
            openingDate: Yup.date().required("Opening date is required"),
            closingDate: Yup.date().required("Closing date is required").min(Yup.ref("openingDate"), "Closing date cannot be before the opening date"),
        }),
        onSubmit: (values) => {
            setActive("Preview");
            dispatch(setRepostDetails({ closingDate: values.closingDate, openingDate: values.openingDate }));
        },
    });

    const handleSubmit = async () => {
        await repostJob({
            variables: {
                jobId,
                closeDate: moment(repostJobDetailForm.values.closingDate).toDate(),
                openDate: moment(repostJobDetailForm.values.openingDate).toDate(),
            },
        })
            .then(() => {
                if (Number(employer?.company?.subscription?.package?.jobsQuota || 0) > Number(activeJobs || 0)) {
                    repostJobDetailForm.resetForm();
                    dispatch(clearRepostJob());
                    toast(
                        JSON.stringify({
                            type: "success",
                            title: "Job Reposted Successfully",
                        })
                    );
                    navigate({
                        search(prev) {
                            return { ...prev, id: undefined, drawer: undefined, modal: "repost_success" };
                        },
                    });
                } else {
                    toast(
                        JSON.stringify({
                            type: "error",
                            title: "Active job quota exceeded",
                        })
                    );
                }
            })
            .catch(() => {
                JSON.stringify({
                    type: "error",
                    title: "Something wrong happened",
                });
            });
    };

    useEffect(() => {
        repostJobDetailForm.setValues({ openingDate: moment(job?.openDate).format("YYYY-MM-DD") || "", closingDate: moment(job?.closeDate).format("YYYY-MM-DD") || "" });
        dispatch(
            setRepostJobPreview({
                jobTitle: job?.title || "",
                jobType: job?.type || "",
                jobLocation: job?.location || "",
                modeOfWork: job?.mode || "",
                openingDate: moment(job?.openDate).format("YYYY-MM-DD"),
                closingDate: moment(job?.closeDate).format("YYYY-MM-DD"),
                currency: job?.salary?.currency || "",
                maximumSalary: Number(job?.salary?.maximum || 0),
                minimumSalary: Number(job?.salary?.minimum || 0),
                about: job?.about || "",
                benefits: job?.benefits || "",
                duration: "",
                requirements: job?.requirements || "",
                responsibilities: job?.responsibilities || "",
            })
        );
    }, [jobId, loading, job?.id]);
    return (
        <Drawer open={open} loading={loading} setOpen={setOpen} title="Repost Job">
            <>
                <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                    <div className="flex justify-start items-center border-b w-full h-10 text-sm lg:space-x-10 space-x-5 overflow-scroll overflow-x-scroll no-scrollbar sticky top-0 z-30 bg-white">
                        <RepostTabButton title={"Date Adjustment"} active={active} setActive={setActive} />
                        <RepostTabButton title={"Preview"} active={active} setActive={setActive} />
                    </div>
                    <div className="w-full mt-5">
                        {loading ? (
                            <div className="w-full flex justify-center items-center">
                                <ClockLoader color="green" />
                            </div>
                        ) : (
                            <>
                                <Show if={active === "Date Adjustment"}>
                                    <RepostJobDetail form={repostJobDetailForm} />
                                </Show>
                                <Show if={active === "Preview"}>
                                    <RepostJobPreview loading={loadingRepost} handleSubmit={handleSubmit} setActive={setActive} employerQuota={Number(employer?.company?.subscription?.package?.jobsQuota || 0)} activeQuota={Number(activeJobs || 0)} />
                                </Show>
                            </>
                        )}
                    </div>
                </div>
            </>
        </Drawer>
    );
};

export default RepostDrawer;
