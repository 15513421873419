import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Show } from "@/components";
import CreateUserModal from "../components/modals/create-user";
import { useUrlState } from "@/utils";
import { useAppNavigate, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { useUsers } from "@/hooks";
import Avatar from "@/components/core/avatar";
import DefaultLoader from "@/components/loaders/default-loader";
import _ from "lodash";
import EditUserModal from "../components/modals/edit-user";
import { hasPermission, PERMISSIONS } from "@/permissions";
import DeleteUserModal from "../components/modals/delete-user";

const Users = () => {
    const navigate = useAppNavigate();
    const currentUserPermissions = useCurrentUserPermissions();
    const [modal, setModal] = useUrlState("modal");
    const [search, setSearch] = useUrlState("search");
    const employer = useCurrentUser();
    const { users, loading } = useUsers({ variables: { filter: { companyId: { eq: employer?.companyId || "" }, fullName: !_.isEmpty(search) ? { like: search } : undefined }, pagination: {} } });

    return (
        <>
            <div>
                <div className="flex justify-between  my-6 items-center space-x-2">
                    <div className="ring-1 ring-gray-300  px-3 w-96 h-9 flex items-center gap-2 rounded-md shadow">
                        <MagnifyingGlassIcon className="w-3 h-3 text-gray-500" aria-hidden="true" />
                        <input type="text" placeholder="Search users" className="w-full h-full outline-none text-sm " value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div>
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_CREATE)}>
                            <Button size="sm" onClick={() => setModal("createUser")}>
                                <span className="text-xs">Add New User</span>
                            </Button>
                        </Show>
                    </div>
                </div>
                {loading ? (
                    <div className="w-full h-full">
                        <DefaultLoader />
                    </div>
                ) : !loading && _.isEmpty(users) ? (
                    <div>No Users Found</div>
                ) : (
                    <div className="mt-5 border rounded-md w-full overflow-scroll overflow-x-scroll flex">
                        <div className="flex-shrink-0  min-w-full">
                            <table className="divide-y divide-gray-200  w-full">
                                <thead>
                                    <tr className="bg-[#F9FAFB] h-10 ">
                                        <th scope="col" className="text-left pl-5 text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0 ">
                                            Name
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0">
                                            Phone Number
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0">
                                            Position
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-20 flex-shrink-0">
                                            Role
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 w-20 flex-shrink-0">
                                            <span className="sr-only">Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {users?.map((user) => (
                                        <tr key={user?.id}>
                                            <td className="whitespace-nowrap text-xs text-start w-40 pl-5">
                                                <div className="flex items-center w-full space-x-2">
                                                    <Avatar size="xs" src={user?.profilePicture || ""} alt={user?.fullName || ""} />
                                                    <div className=" flex-1  w-full">
                                                        <p className="font-medium w-full truncate max-w-[90%] text-xs text-gray-900">{user?.fullName}</p>
                                                        <p className="mobile:text-xs/5 max-w-[90%] mobile:max-w-[80%]  truncate  text-[11px] text-gray-500">{user?.email}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{user?.phone}</td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{user?.position}</td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{user?.role?.name}</td>
                                            <td className="relative  whitespace-nowrap py-5 text-left text-xs font-semibold w-20 space-x-3">
                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_UPDATE)}>
                                                    <button
                                                        onClick={() => {
                                                            navigate({
                                                                search(prev) {
                                                                    return { ...prev, modal: "editUser", id: (user?.id as string) || undefined };
                                                                },
                                                            });
                                                        }}
                                                        className="text-primary hover:text-green-600 mobile:hidden">
                                                        Edit<span className="sr-only"> {user?.fullName} information</span>
                                                    </button>
                                                </Show>
                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_DELETE) && employer?.role?.code === "HR001" && user?.role?.code !== "HR001"}>
                                                    <button
                                                        onClick={() => {
                                                            navigate({
                                                                search(prev) {
                                                                    return { ...prev, modal: "deleteUser", id: (user?.id as string) || undefined };
                                                                },
                                                            });
                                                        }}
                                                        className="text-red-400 hover:text-red-600 mobile:hidden">
                                                        Delete<span className="sr-only"> {user?.fullName} information</span>
                                                    </button>
                                                </Show>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            {modal === "createUser" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_CREATE) && (
                <CreateUserModal
                    open={modal === "createUser"}
                    setOpen={() =>
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        })
                    }
                />
            )}

            {modal === "editUser" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_UPDATE) && (
                <EditUserModal
                    open={modal === "editUser"}
                    setOpen={() =>
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        })
                    }
                />
            )}

            {modal === "deleteUser" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER_DELETE) && (
                <DeleteUserModal
                    open={modal === "deleteUser"}
                    setOpen={() =>
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        })
                    }
                />
            )}
        </>
    );
};

export default Users;
