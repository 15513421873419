import { makeVar, useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "../../config";
import { Employer } from "../generated/types";
import { useNavigate } from "react-location";
import { LocationGenerics } from "@/router/location";

export const isLoggedInVar = makeVar<boolean>(!!Cookies.get(`employer:auth:token`));
export const currentTokenVar = makeVar<string | null>(Cookies.get(`employer:auth:token`) || null);
export const currentUserVar = makeVar<Employer>(JSON.parse(Cookies.get(`employer:auth:user`) ?? "{}") ?? null);
export const currentUserPermissionVar = makeVar<string[]>(JSON.parse(Cookies.get(`employer:auth:permissions`) ?? "[]") ?? []);

export const useCurrentUser = () => useReactiveVar(currentUserVar);
export const useCurrentUserPermissions = () => useReactiveVar(currentUserPermissionVar);
export const useCurrentToken = () => useReactiveVar(currentTokenVar);
export const useIsLoggedIn = () => useReactiveVar(isLoggedInVar);

export const useAppNavigate = () => useNavigate<LocationGenerics>();

export const setAuth = ({ user, token }: { user: Employer; token: string }) => {
    currentUserVar(user);
    Cookies.set(`employer:auth:user`, JSON.stringify(user), {
        ...config.cookies,
        expires: 1,
    });
    Cookies.set(`employer:auth:permissions`, JSON.stringify([...((user?.role?.permissions as string[]) ?? []), ...(user?.company?.subscription?.package?.permissions ?? [])])),
        {
            ...config.cookies,
            expires: 2,
        };

    currentUserPermissionVar([...((user?.role?.permissions as string[]) ?? []), ...(user?.company?.subscription?.package?.permissions ?? [])]);

    isLoggedInVar(true);
    currentTokenVar(token);
    Cookies.set(`employer:auth:token`, token, {
        ...config.cookies,
        expires: 1,
    });
};

export const setMe = (user: Employer) => {
    currentUserVar(user);
    Cookies.set(`employer:auth:permissions`, JSON.stringify([...((user?.role?.permissions as string[]) ?? []), ...(user?.company?.subscription?.package?.permissions ?? [])])),
        {
            ...config.cookies,
            expires: 2,
        };
    Cookies.set(`employer:auth:user`, JSON.stringify(user), {
        ...config.cookies,
        expires: 1,
    });
};

export const clearAuth = () => {
    isLoggedInVar(false);
    currentTokenVar(null);
    currentUserVar(undefined);
    currentUserPermissionVar([]);
    // TODO: handle extra checks for better user experience
    Object.keys(Cookies.get()).forEach((key) => {
        Cookies.remove(key, { ...config.cookies });
    });
};
