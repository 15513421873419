import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import { CancelSubscriptionMutation, CancelSubscriptionMutationVariables } from "@/apollo/generated/types";
import { CANCEL_SUBSCRIPTION } from "@/apollo/graphql/mutations/subscription";
import { Button, Modal } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { useUpdateEmployer } from "@/hooks";
import { useMutation } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function CancelPlan({ open, setOpen }: Props) {
    const { refetch } = useUpdateEmployer();
    const navigate = useAppNavigate();
    const employer = useCurrentUser();
    const [cancelSubscription, { loading }] = useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CANCEL_SUBSCRIPTION, { refetchQueries: ["GetEmployers"] });
    const handleReload = () =>
        setTimeout(() => {
            window.location.reload();
        }, 300);

    const handleDelete = async () => {
        await cancelSubscription({ variables: { companyId: employer?.companyId || "" } }).then(() => {
            toast(
                JSON.stringify({
                    type: "success",
                    title: "Subscription Cancelled Successffully",
                })
            );
            refetch();
            navigate({
                search(prev) {
                    return { ...prev, modal: undefined, id: undefined };
                },
            });
            clearTimeout(handleReload());
            handleReload();
        });
    };
    return (
        <Modal title={""} open={open} setOpen={setOpen} hideActions={true} size="md">
            <div>
                <div className="text-center mt-2">
                    <h3 className="font-montserrat text-lg font-semibold text-black">Cancel Subscription</h3>
                    <div className="flex justify-between items-start p-2 bg-red-100 gap-2 my-3">
                        <span>
                            <ExclamationCircleIcon className="text-red-500 h-5 w-5" />
                        </span>
                        <p className="font-montserrat text-xs text-red-600 text-start">Your subscription is currently active but will not be renewed for the next month.</p>
                    </div>
                    <div className="">
                        <p className="font-montserrat text-xs/5 text-black ">Are you sure you want to cancel your current subscription?</p>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex justify-end items-center gap-5">
                <div>
                    <Button
                        type="button"
                        variant="minimal"
                        onClick={() => {
                            setOpen(false);
                            navigate({
                                search(prev) {
                                    return { ...prev, modal: undefined, id: undefined };
                                },
                            });
                        }}>
                        Close
                    </Button>
                </div>
                <div>
                    <Button type="button" onClick={() => handleDelete()} variant="danger">
                        {loading ? <ButtonLoader title="Canceling ..." /> : "Proceed"}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
